/* =================================================

Template Name:  Natasha - One page portfolio template
Author: MouriTheme
Version: 1.0
Design and Developed by: MouriTheme

NB: This is the main stylesheet of this theme.

=================================================== */

/*
Table of content

1. Default css
2. Preloader css
3. Nav area css
4. Mouse Animate Icon css
5. Banner area css
6. About area css
7. Resume area css
8. blog area css
9. Portfolio area css
10. Testimonial area css
11. Invitation area css
12. Contact area css
13. Footer area css

*/


/*---------- 1. Default css starts ------------*/

html,body{
    height: 100%;
}

body{
    font-family: 'Montserrat', sans-serif;
    width: 100%;
    height: 100%;
    font-size: 15px;
    line-height: 1.7;
    color: #333333;
    font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6{
    color: #333333;
    font-weight: 600;
    text-transform: uppercase;
}

h3{
    font-size: 20px;
    text-transform: none;
}

p {
    letter-spacing: 0;
    line-height:1.8;
}

a{
    text-decoration: none;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -ms-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
}

a:focus{
    outline:none;
    text-decoration: none;
}

ul,li{
    margin: 0;
    padding: 0;
}

img {
    max-width: 100%;
    height: auto;
}

.section-padding{
    padding: 50px 0;
}
.section-header{
    text-align: center;
    margin-bottom: 5%;
}
.section-header h1{
    font-size: 30px;
}

.section-header .line1 {
    height: 2px;
    width: 200px;
    background: #E15F41;
    margin: auto;
}

.section-header .line2 {
    height: 2px;
    width: 150px;
    background: #222222;
    margin: 15px auto;
}

/*---------- default css ends ------------*/

/*-------------- 2. Preloader css starts ---------------*/

.loader_bg {
    position: fixed;
    z-index: 9999999;
    background: #fff;
    width: 100%;
    height: 100%;
}

.loader {
    border: 0 solid transparent;
    border-radius: 50%;
    width: 150px;
    height: 150px;
    position: absolute;
    top: calc(50vh - 75px);
    left: calc(50vw - 75px);
}

.loader:before, .loader:after {
    content: '';
    border: 1em solid #E15F41;
    border-radius: 50%;
    width: inherit;
    height: inherit;
    position: absolute;
    top: 0;
    left: 0;
    animation: loader 2s linear infinite;
    opacity: 0;
}

.loader:before {
    animation-delay: .5s;
}

@keyframes loader {
    0% {
        transform: scale(0);
        opacity: 0; }
    50% {
        opacity: 1; }
    100% {
        transform: scale(1);
        opacity: 0; }
}

/*---------- preloader css ends -------------*/

/*---------- 3. Nav area css starts -------------*/

.nav-area{
    height: 65px;
}

.nav-area.sticky_navigation{
    background: #ffffff;
    height: 55px;
}

.sticky_navigation{
    -webkit-transition: all 0.4s ease-out;
    -moz-transition: all 0.4s ease-out ;
    -ms-transition: all 0.4s ease-out ;
    -o-transition: all 0.4s ease-out ;
    transition: all 0.4s ease-out ;
    -webkit-box-shadow: 0px 3px 4.6px 0.3px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 3px 4.6px 0.3px rgba(0,0,0,0.25);
    box-shadow: 0px 3px 4.6px 0.3px rgba(0,0,0,0.25);
}

.sticky_navigation .main-menu{
    margin-top: 0;
}

.sticky_navigation .navbar-brand, .sticky_navigation .navbar-brand span {
    color: #E15F41;
}

.sticky_navigation .navbar-brand:hover, .sticky_navigation .navbar-brand:focus, .sticky_navigation .navbar-brand span {
    color: #E15F41;
}

.sticky_navigation .nav li a {
    color: #333333;
}

.sticky_navigation .nav li.active a{
    color: #E15F41;
}

.sticky_navigation .navbar-brand:hover{
    color: #333333;
}

.main-menu{
    -webkit-transition: all 0.4s ease-out;
    -moz-transition: all 0.4s ease-out;
    -ms-transition: all 0.4s ease-out;
    -o-transition: all 0.4s ease-out;
    transition: all 0.4s ease-out;
    margin-top: 10px;
}

.navbar{
    border:0;
    margin-bottom: 0;
}

.navbar-brand{
    padding: 15px 0;
    text-transform: uppercase;
    font-size: 25px;
    display: block;
    color: #ffffff;
    font-weight: 800;
}

.navbar-brand:focus, .navbar-brand:hover {
    text-decoration: none;
    color: #ffffff;
}

.navbar-right li{
    display: inline-block;
    float: none;
}

.navbar-right li a{
    color: #ffffff;
    text-transform: uppercase;
    font-weight: 600;
}

.navbar-right li.active a{
    font-weight: bold;
    color: #ffffff;
}

.nav > li > a {
    padding: 15px 10px;
}

.nav > li > a:hover,
.nav > li > a:active,
.nav > li > a:focus{
    background: none;
}

.navbar-toggle .icon-bar{
    background: #777777;
}

/*---------- Nav area css ends -------------*/

/*----------- 4. Mouse Animate Icon Starts ------------------*/

.mouse-icon {
    position: absolute;
    left: 50%;
    bottom: -160px;
    border: 2px solid #fff;
    border-radius: 16px;
    height: 50px;
    width: 30px;
    margin-left: -17px;
    display: block;
    z-index: 10;
}

.mouse-icon .wheel {
    -webkit-animation-name: drop;
    -webkit-animation-duration: 1s;
    -webkit-animation-timing-function: linear;
    -webkit-animation-delay: 0s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-play-state: running;
    animation-name: drop;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-play-state: running;
}

.mouse-icon .wheel {
    position: relative;
    border-radius: 10px;
    background: #fff;
    width: 4px;
    height: 10px;
    top: 4px;
    margin-left: auto;
    margin-right: auto;
}

@keyframes drop	{
    0%   { top:5px;  opacity: 0;}
    30%  { top:10px; opacity: 1;}
    100% { top:25px; opacity: 0;}
}

/* --------- Mouse Animate Icon ends -------------- /*

/* --------- 5. Banner area css starts -----------*/

.banner-area{
    background-image: url(src/assets/images/home/working-in-dark-mode.jpg);
    background-color: rgb(115, 123, 150);
    background-position: center;
    -webkit-background-size:cover;
    background-size:cover;
    position: relative;
    height: 100%;
}

.banner-table{
    display: table;
    margin: 0;
    position: relative;
    height: 100%;
    width: 100%;
    z-index: 1;
}

.banner-table-cell{
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

.welcome-text{
    color: #ffffff;
}

.welcome-text h2 {
    font-size: 2.8em;
    font-weight: 300;
    color: #ffffff;
}

.text-affect,
.typed-cursor {
    display: inline;
    font-size: 4em;
    font-weight: 700;
    color: #ffffff;
}

canvas{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background: rgba(0,0,0,0.3);
}

.banner-btn a {
    background-color: #E15F41;
    color: #ffffff;
    padding: 10px 35px;
    display: inline-block;
    margin-top: 25px;
    border-radius: 25px;
    text-decoration: none;
    letter-spacing: 2px;
}

@media (max-width: 991px){
    .welcome-text{
        text-align: center;
        margin: 25px auto;
    }
}

/* --------------  Banner area css ends --------------- */

/*---------------6. About area starts ----------------*/

.about-left {
    -webkit-box-shadow: 10px 10px 1px #c6c6c6,20px 20px 1px #dddddd;
    -moz-box-shadow: 10px 10px 1px #c6c6c6,20px 20px 1px #dddddd;
    box-shadow: 10px 10px 1px #c6c6c6,20px 20px 1px #dddddd;
}
.about-right {
    padding: 2% 5%;
}
.about-right h1 {
    border-left: 3px solid #E15F41;
    padding-left: 15px;
    font-size: 30px;
    line-height:40px;
}
.text-primary {
    color: #E15F41;
}
.socials {
    margin-top: 5%;
}
.socials li {
    display: inline-block;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    margin-right: 25px;
}
.socials a {
    display: inline-block;
    height: 40px;
    width: 40px;
    font-size: 16px;
    color: #222222;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    border: 2px solid #222222;
    background: transparent;
    line-height: 2.5;
    text-align: center;
}
.socials a:hover{
    background: #222222;
    color: #ffffff;
}

.socials a i{
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.spelling {

    text-transform: initial;
    font-size: 0.5em;
}


/*------- 7. Resume area css starts -------*/

.resume-area h5 {
    font-size: 20px;
}
#resume h1 {
    padding-left: 15px;
    font-size: 30px;
}

.timeline {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
}

.timeline::after {
    content: '';
    position: absolute;
    width: 6px;
    background-color: #000000;
    top: 0;
    bottom: 0;
    left: 0;
    margin-left: -3px;
}

.wrapper {
    padding: 10px 40px;
    position: relative;
    background-color: inherit;
    width: 100%;
}

.wrapper::after {
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    right: -17px;
    background-color: white;
    border: 4px solid #E15F41;
    top: 20px;
    border-radius: 50%;
    z-index: 1;
}

.right {
    left: 0;
}

.right::before {
    content: " ";
    height: 0;
    position: absolute;
    top: 22px;
    width: 0;
    z-index: 1;
    left: 30px;
    border: medium solid #000;
    border-width: 10px 10px 10px 0;
    border-color: transparent #000 transparent transparent;
}

.right::after {
    left: -12px;
}

.content {
    padding: 20px 30px;
    background-color: white;
    position: relative;
    border-radius: 6px;
}


.content {
    border: 1px solid #000000;
    color: #000000;
}
.education-area h6,.experience-area h6 {
    font-size: 20px;
}

#resume h3 {
    font-size: 15px;
    color: #E15F41;
    margin: 10px auto;
    text-transform: uppercase;
}

#resume h4 {
    font-size: 15px;
    color:#000000;
}

#resume h4 span {
    padding-left: 10px;
}

#resume h5 {
    padding-left: 40px;
}

#resume p {
    font-size: 13px;
}

/*------ Resume area ends ---------*/

/*---------------8. blog area Starts--------------*/

.blog-area {
    background-color: #eeeeee;
}

.blog-area a {
    text-decoration: none;
    color: inherit;
}

.blog-icon i {
    font-size: 35px;
    color: #ffffff;
}

.blog-content h3 {
    line-height: 1.9;
    text-transform: uppercase;
}

.blog-icon {
    border: 1px solid #E15F41;
    display: inline-block;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    padding: 20px;
    background-color: #E15F41;
}

.single-blog {
    padding: 40px 20px;
    box-shadow: rgba(58,78,95,0.2) 0 10px 16px, rgba(58,78,95,0.05) 0 -5px 16px;
    margin-bottom: 15px;
    -webkit-border-radius:10px;
    -moz-border-radius:10px;
    border-radius:10px;
}

.single-blog:hover {
    box-shadow: 1px 3px 4px 5px rgba(199,199,199,.8);
    transition: ease .09s;
}

.single-blog:hover .blog-icon{
    background-color: #ffffff;
    transition: ease .3s;
    border-color: #E15F41;
}

.single-blog:hover .blog-icon i{
    color: #E15F41;
}

.single-blog:hover{
    background-color: #E15F41;
    -webkit-transition: .9s;
    -moz-transition: .9s ;
    -ms-transition: .9s ;
    -o-transition: .9s ;
    transition: .9s ;
}
.single-blog:hover .blog-content{
    color: #ffffff;
}
.single-blog:hover .blog-content h3{
    color: #ffffff;
}

/*--------------- blog area ends --------------*/

/*--------------9. Portfolio area starts-----------*/

#portfolio h1 {
    border-left: 3px solid #E94E63;
    padding-left: 15px;
    font-size: 30px;
}


.gallery-items {
    position: relative;
    margin-bottom: 30px;
    background-color: #ffebb1;
}

.gallery-items .img {
    display: block;
    position: relative;
}

.gallery-items .img::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(255,255,255,0.9);
    color: #E94E63;
    opacity: 0;
    -webkit-transition: .7s;
    -moz-transition: .7s;
    -ms-transition: .7s;
    -o-transition: .7s;
    transition: .7s;
}

.gallery-items .img img {
    width: 100%;
}

.gallery-items:hover .img::before {
    content: "";
    display: block;
    position: absolute;
    top: 10px;
    right: 10px;
    bottom: 10px;
    left: 10px;
    opacity: 1;
}

.gallery-items:hover .gallery-text {
    opacity: 1;
    -webkit-transition: .9s;
    -moz-transition: .9s ;
    -ms-transition: .9s ;
    -o-transition: .9s ;
    transition: .9s ;
}

.gallery-text {
    opacity: 0;
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    max-width: 80%;
    text-align: center;
}

.gallery-text i {
    font-size: 2em;
    cursor: pointer;
}

.gallery-text h3 {
    text-transform: uppercase;
    position: relative;
    margin-bottom: 5px;
    padding-top: 15px;
    padding-bottom: 0;
    font-size: 17px;
    letter-spacing: 2px;
}

.gallery-text h3:after {
    height: 3px;
}

.gallery-text h2 {
    color: #E94E63;
    font-size: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: 700;
}

.portfolio-mini-desc p {
    margin: 0;
    text-transform: capitalize;
    font-size: 14px;
    letter-spacing: .05em;
    font-weight: 300;
}

/*--------------Portfolio area Ends-----------*/

/*------------10. Testimonial area starts ---------*/

.testimonial-content p {
    font-weight: 400;
    font-style: italic;
    font-size: 18px;
}

.testimonial-area{
    background-color: #eeeeee;
}

.single-testimonial {
    margin: 0 49px 29px;
    border-right: 5px solid #E15F41;
    border-left: 5px solid #E15F41;
    padding: 40px 30px 40px;
    position: relative;
    background-color: #eeeeee;
    color: #010446;
    text-align: center;
}

.single-testimonial::before {
    content: "";
    background-color: #E15F41;
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 5px;
}

.single-testimonial::after {
    content: "";
    background-color: #E15F41;
    right: 0;
    width: 50%;
    height: 5px;
    position: absolute;
    bottom: 0;
}

.single-testimonial .testimonial-content{
    margin-bottom: 30px;
    font-size: 15px;
}

.single-testimonial .pic {
    display: inline-block;
    width: 80px;
    height: 80px;
    margin-right: 15px;
    border-radius: 50%;
    overflow: hidden;
}

.single-testimonial .pic img{
    width: 100%;
    height: 100%;
}

.single-testimonial .testimonial-title small{
    font-size: 15px;
    color:#292F36;
}

.owl-theme .owl-controls{
    margin-top: 50px;
    margin-left: 30px;
}

.owl-dots{
    text-align: center;
}

.owl-dot {
    display: inline-block;
    height: 15px !important;
    width: 15px !important;
    background-color: #222222 !important;
    opacity: 0.8;
    border-radius: 50%;
    margin: 0 5px;
}

.owl-dot.active {
    background-color: #E15F41 !important;
}

/*------------ Testimonial area ends ---------*/

/*------- 11. Invitation area starts------*/

.invitation-area {
    background: #333333;
    color: #ffffff;
}
.single-invite h2 {
    font-size: 27px;
    line-height: 37px;
    margin-bottom: 20px;
    padding-top: 10px;
    font-weight: 600;
    color: #ffffff;
}
.single-invite p {
    width: 70%;
    margin: auto;
    color: #ffffff;
}
.single-invite a {
    display: inline-block;
    color: #fff;
    padding: 10px 20px;
    text-transform: uppercase;
    background: #E15F41;
    margin-top: 20px;
    border-radius: 30px;
    margin-left: 5px;
    margin-right: 5px;
    font-size: 14px;
}

/*-------invitation area ends------*/

/*-------------12. Contact area starts -------------*/


.contact-area .form-control{
    border:2px solid #a2a2a2;
    box-shadow: none;
    padding: 6px;
    border-radius: 0;
    margin-bottom: 30px;
}

.top-contact-text {
    margin-bottom: 50px;
    text-align: center;
}

.contact-area textarea.form-control{
    border:2px solid #a2a2a2;
    padding: 6px;
    height: 250px;
    margin-bottom: 30px;
}

.btn.btn-send {
    background-color: #E15F41;
    color: #ffffff;
    padding: 10px 35px;
    display: inline-block;
    margin-top: 30px;
    border-radius: 25px;
    text-decoration: none;
    letter-spacing: 2px;
}

.top-contact-text h2{
    margin-top: 2px;
    margin-bottom: 5px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 3px;
}

.top-contact-text p{
    line-height: 21px;
    letter-spacing: 2px;
    font-size: 11px;
    color: #777;
}

.contact-icon li{
    display: inline-block;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    margin-right: 25px;
}

.contact-icon a {
    display: inline-block;
    height: 40px;
    width: 40px;
    font-size: 16px;
    color: #222222;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    border: 2px solid #222222;
    background: transparent;
    line-height: 2.5;
    text-align: center;
}

.contact-icon a:hover{
    background: #222222;
    color: #ffffff;
}

.contact-icon a i{
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
.contact-icon {
    text-align: center;
    margin-bottom: 25px;
}

/*------------- Contact area ends -------------*/

/*--------------11. Footer area starts -------------*/


.footer-area {
    background: #232323;
    color: #ffffff;
    padding: 30px 0;
}
.footer-area a{
    color: #E15F41;
}

/*--------------- Footer area ends -------------*/









































